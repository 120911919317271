/* eslint-disable no-undef */

export default defineNuxtMiddleware(async (context) => {
  const { app } = context;

  // Reading IN_MAINTEN_MODE value from .env
  const maintenValue =
    app.env.IN_MAINTEN_MODE ||
    app.env.inMaintenModeEnv ||
    app.$config.inMaintenModeConfig;

  const inMaintenanceMode = maintenValue === "true";

  if (inMaintenanceMode) {
    await context.redirect("/maintenance");
  }
});
